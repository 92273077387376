@import "../../lg-them.scss";

.lg-list-itemlist{
    padding: 10px 0;
    
}
.lg-list-item{
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: .5px dotted rgb(182, 180, 180);
    background-color: $lg-list-item-bgcolor;
    
}

.lg-list-item:hover{
    background-color: $lg-list-item-hover-bgcolor;
}