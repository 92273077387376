@import "../../lg-them.scss";

.post-body > img {
  border-radius: 20px;
  width: 100%;
}
.post {
  display: flex;
  margin: 5px;
  align-items: flex-start;
  :hover{
    background-color: rgb(241, 220, 232);
  }
  // border-bottom: 1px solid;
}
.post-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.post-footer-left > * {
  padding-left: 2rem;
  cursor: pointer;
}
.post-footer-right > * {
  padding-left: 2rem;
  cursor: pointer;
}

.post-badge {
  font-size: 14px !important;
  color: $lg-them-color;
}
.post-headerSpecial {
  font-weight: 600;
  font-size: 12px;
  color: gray;
}
.post-headerText > h3 {
  font-size: 15px;
  margin-bottom: 5px;
}
.post-headerDescription {
  font-size: 15px;
  margin-bottom: 20px;
}
.post-body {
  flex: 1;
  padding: 10px;
}
.post-avatar {
  padding: 20px;
}

.lg-post-text-show-more {
  border: hidden;
  background-color: #fff;
  cursor: pointer;
  text-decoration: underline;
}
