
.lg-default-view{
    width: 100%;
    height: calc(100% - 65px);
    display: flex;
    flex-direction: row;
}
.lg-default-left-panel{
    width: 20%;
    height: 100%;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // border: 1px solid;
}

.lg-default-main-container{
    flex-grow: 1;
    margin: 5px;
    overflow: scroll;
    width: 60%;
    // border: 1px solid;
    height: 100%;
}

.lg-default-right-panel{
    width: 25%;
    height: 100%;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
    // border: 1px solid;
}