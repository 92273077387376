@import '../../lg-them.scss';

.sidebar-post-button{
    background-color: $lg-them-color !important;
    border: none !important;
    color: rgb(12, 11, 11) !important;
    font-weight: 700 !important;
    text-transform: inherit !important;
    border-radius: 10px !important;
    height: 50px !important;
    margin-bottom: 20px !important;
}
.sidebar {
    border-right: 1px solid $lg-them-color;
    flex: 0.25;
    min-width: 250px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}
.leftpanel-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
}
.leftpanel-option:hover {
    background-color: $lg-list-item-bgcolor;
    color: var($lg-them-color);
    transition: color 100ms ease-out;
}
.leftpanel-option > .MuiSvgIcon-root {
    padding: 20px;
}
.leftpanel-option > h2 {
    font-weight: 700;
    font-size: 20px;
    margin-right: 20px;
}
.leftpanel-option--active {
}
