@import "./lg-them.scss";

.App {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  height: 100vh;
  .MuiAppBar-colorPrimary , .MuiButton-containedPrimary{
    background-color: $lg-background;
  }
  .MuiFilledInput-root{
    background-color: $lg-list-item-bgcolor;
  }

}

.body{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'HelveticaNeue', 'Helvetica Neue', Helvetica, 'Roboto', 'Segoe UI', Arial, sans-serif;
}

h1,h2,h3,h4{
  color:rgb(70, 69, 69)
}